import { Trans } from "@lingui/macro";
import styled from "@emotion/styled";
import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import React, { useState } from "react";
import { navigate } from "gatsby";
import { useLocalization } from "gatsby-theme-i18n";
import Container from "../../components/Container";
import HeroLanding from "../../components/HeroLanding";
import ProductModal from "../../components/ProductModal";
import SecondaryButton from "../../components/SecondaryButton";
import CardColor from "../../components/CardColor";
import blueIcon from "../../images/icons/blue.png";
import greenIcon from "../../images/icons/green.png";
import purpleIcon from "../../images/icons/purple-ma.png";
import yellowIcon from "../../images/icons/yellow.png";
// import CarouselImages from "../../components/CarouselLogos";
// import allfundsimg from "../../images/data-analitys/allfunds.svg";
import goldenDataHub from "../../images/data-analitys/slider-hero-1.png";
import dhImage from "../../images/data-analitys/slider-hero-3.png";
import dhImage2 from "../../images/data-analitys/slider-hero-2.png";
// import fatcset from "../../images/data-analitys/facset.svg";
// import ice from "../../images/data-analitys/ice.svg";
// import lipper from "../../images/data-analitys/lipper.svg";
// import morningstar from "../../images/data-analitys/morningStar.svg";
// import mainstreet from "../../images/data-analitys/main-street.png";
// import moodys from "../../images/data-analitys/moodys.svg";
// import lseg from "../../images/data-analitys/lseg.svg";
// import sAndpGlobal from "../../images/data-analitys/sp-global.png";
// import six from "../../images/data-analitys/six.svg";
import globeIcon from "../../images/data-analitys/globe.svg";
import fileInvoice from "../../images/data-analitys/file-invoice.svg";
import squareCheck from "../../images/data-analitys/square-check.svg";
import check from "../../images/data-analitys/Check.svg";
import line from "../../images/data-analitys/arrow.svg";
import AnimatedDiv from "../../components/AnimatedDiv";
import { theme } from "../../themeV2";

const BUILD_ITEMS = [
  {
    title: <Trans>Define your list products</Trans>,
    icon: squareCheck,
    description: (
      <Trans>
        Define the type of products across all asset class:
      </Trans>
    ),
    list: ["Equity", "Funds", "Fixed Income", "Structured products..."],
  },
  {
    title: <Trans>Select your dataset</Trans>,
    icon: fileInvoice,
    description: (
      <Trans>
        Select the data of your interest and invoke all what you need
      </Trans>
    ),
  },
  {
    title: <Trans>Create your own universe</Trans>,
    icon: globeIcon,
    description: (
      <Trans>
        Build your own data feed, defining data quality and markets & exchange.
      </Trans>
    ),
  },
];

const ITEMS = [
    {
        icon: yellowIcon,
        color: theme.palette.colors.yellow.main,
        title: (
          <Trans>
            Accelerated integration process
          </Trans>
        ),
        description: (
          <Trans>
            Build new business lines by leveraging on 100+ pre integrated data vendors
          </Trans>
        ),
      },
  {
    icon: purpleIcon,
    color: theme.palette.colors.purple.main,
    title: <Trans>Multi asset</Trans>,
    description: (
      <Trans>
        Integration of any asset type in the market
      </Trans>
    ),
  },
  {
    icon: greenIcon,
    color: theme.palette.colors.green.main,
    title: <Trans>One single entry point and support</Trans>,
    description: (
      <Trans>
        One place to invoke all what you need
      </Trans>
    ),
  },
  {
    icon: blueIcon,
    color: theme.palette.colors.blue.light,
    title: <Trans>Real-time connectivity</Trans>,
    description: (
      <Trans>
        Real-time data connectivity with multiple data sources
      </Trans>
    ),
  },
];

// const ITEMS_CAOROUSEL = [
//   {
//     key: 1,
//     image: allfundsimg,
//   },
//   {
//     key: 2,
//     image: fatcset,
//     },
//     {
//     key: 3,
//     image: ice,
//     },
//     {
//     key: 4,
//     image: lipper,
//     },
//     {
//     key: 5,
//     image: morningstar,
//     },
//     {
//     key: 6,
//     image: mainstreet,
//     },
//     {
//     key: 7,
//     image: moodys,
//     },
//     {
//     key: 8,
//     image: lseg,
//     },
//     {
//     key: 9,
//     image: sAndpGlobal,
//     },
//     {
//     key: 10,
//     image: six,
//     },
//     {
//       key: 11,
//       image: allfundsimg,
//     },
//     {
//       key: 12,
//       image: fatcset,
//       },
//       {
//       key: 13,
//       image: ice,
//       },
//       {
//       key: 14,
//       image: lipper,
//       },
//       {
//       key: 15,
//       image: morningstar,
//       },
//       {
//       key: 16,
//       image: mainstreet,
//       },
// ];

const sliderItems = [
  {
    id: 1,
    image: goldenDataHub,
  },
  {
    id: 2,
    image: dhImage,
  },
  {
    id: 3,
    image: dhImage2,
  },
];

function DueDiligenceHome() {
  const [hoveredCard, setHoveredCard] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const isDownLg = useMediaQuery(theme.breakpoints.down("lg"));
  const { locale } = useLocalization();

  const handleOnclickInfo = () => {
    navigate(`/${locale}/data-analytics/request-info/`);
  };

  const renderRequestButton = () => {
    return (
      <SecondaryButton
        handleOnclickInfo={() => handleOnclickInfo()}
        text={<Trans>Start the process</Trans>}
      />
    );
  };

  const renderItem = ({ title, description, color, icon }) => {
    return (
      <CardColor color={color} icon={icon} title={title} description={description} />
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <HeroLanding
        title={
          <Trans>Market data integration. Start the process now.</Trans>
        }
        description={
          <Trans>
            Improve the interaction and automation in one entry point and unlock business opportunities accessing global market data from all major providers.
          </Trans>
        }
        handleOnclickInfo={() => handleOnclickInfo()}
        multimedia={sliderItems}
        isSlider={true}
        grid={{
          columnLeft: { md: 7 },
          columnRight: { md: 5 },
        }}
        kind="Allfunds Golden Data Hub"
        kindColor={theme.palette.colors.purple.main}
        ButtonRequestInfoText={<Trans>Start the process</Trans>}
      />
      {/* <Box pt={{ xs: 5, sm: 5 }} pb={{ xs: 5, sm: 5 }} mb={{ xs: 5, sm: 0 }} sx={{backgroundColor: theme.palette.colors.grey.light}}>
        <Grid item spacing={2} alignItems="start">
          <Stack pt={1} textAlign="center" width="100%">
            <Typography variant="h3">
              <Trans>  Our partners trust on us</Trans>
            </Typography>
          </Stack>
          <Grid item mt={5} xs={12} md={12}>
            <CarouselImages
              items={ITEMS_CAOROUSEL}
            />
          </Grid>
        </Grid>
      </Box> */}
      <Container pb={{ xs: 5 }}>
        <Grid container alignItems="center" justifyContent={{ xs: "center", md: "center", lg: "flex-start" }} flexDirection={{ xs: "column-reverse", md: "column-reverse", lg: "row" }}>
          <Grid container xs={12} md={12} lg={6} pr={{ md: 0, lg: 3 }}>
            <Box
              display="grid"
              gridTemplateColumns={{ xs: "1fr", md: "1fr 1fr" }}
              gridTemplateRows={{ xs: "auto", md: "auto auto" }}
              gap={{ xs: 0, sm: 0, md: 2, lg: 0 }}
              alignItems="center"
              justifyContent={{ xs: "center", sm: "center", lg: "flex-start" }}
              width="100%"
              >
              <Box
                gridColumn={{ xs: "1", md: "1" }}
                gridRow={{ xs: "1", md: "1 / 3" }}
                sx={{ marginRight: { md: 0, lg: "12px !important" } }}
                >
                <AnimatedDiv direction="down">
                  {ITEMS.slice(0, 2).map((item) => (
                  renderItem({ ...item, isHovered: hoveredCard, setIsHovered: setHoveredCard })
                ))}
                </AnimatedDiv>
              </Box>

              <Box
                gridColumn={{ xs: "1", md: "2" }}
                gridRow={{ xs: "2", md: "1 / 3" }}
                pt={{ xs: 0, sm: 0, md: 0, lg: 10 }}
                sx={{ marginLeft: { xs: 0, lg: "12px !important" } }}
              >
                <AnimatedDiv direction="up">
                  {ITEMS.slice(2, 4).map((item) => (
                  renderItem({ ...item, isHovered: hoveredCard, setIsHovered: setHoveredCard })
                ))}
                </AnimatedDiv>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={6} mb={{ xs: 5, sm: 5, md: 5, lg: 0 }} pl={{ md: 0, lg: 3 }}>
            <Stack spacing={1.5}>
              <Typography variant="h3">
                <Trans>Your best place to improve
                  the interaction and automation in one entry point</Trans>
              </Typography>
              <Typography>
                <Trans>
                  A single platform, rationalizing multi-providers and multi-asset, covering any data need.
                </Trans>
              </Typography>
              {renderRequestButton()}
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <Container pt={{ xs: 5, sm: 10 }} pb={{ xs: 5, sm: 18 }}>
        <Container>
          <Stack spacing={1.5} pb={6} textAlign="center" width="100%" justifyContent="center" alignItems={{ xs: "start", sm: "center", md: "center", lg: "center" }}>
            <Typography variant="h3" width={{ sx: "100%", lg: "80%" }} textAlign={{ xs: "start", sm: "center", md: "center", lg: "center" }}>
              <Trans>Maximize your digital transformation and discover how Allfunds can support you</Trans>
            </Typography>
            <Typography textAlign={{ xs: "start", sm: "center", md: "center", lg: "center" }}>
              <Trans>
                Help us to understand your business and needs and our team will start to study your case.
              </Trans>
            </Typography>
            {renderRequestButton()}
          </Stack>
          <Grid container xs={12} md={12} flexDirection={{ xs: "column", sm: "column", lg: "row" }}>
            {BUILD_ITEMS.map(({ title, description, icon, list }, index) => (
              <>
                <Grid item xs={12} pb={{ xs: 3, sm: 5, lg: 0 }} md={index <= 1 ? 4.5 : 3} sx={{ maxWidth: isDownLg ? "100% !important" : "auto" }}>
                  <Stack spacing={1} direction={{ xs: "column", sm: "row" }}>
                    <Stack spacing={1}>
                      <StyledBox>
                        <img width={24} height={24} src={icon} alt={title} style={{ objectFit: "contain" }} />
                      </StyledBox>
                      <Stack spacing={0.5}>
                        <Typography fontSize={20} fontWeight={700}>{title}</Typography>
                        <Typography>
                          {description}
                        </Typography>
                      </Stack>
                    </Stack>
                    {
                  index <= 1 && !isDownLg && (
                    <Stack alignItems="center" justifyContent="center" pl={2} pr={4} sx={{ marginLeft: "0 !important"}}>
                      <img src={line} alt="line" style={{ objectFit: "contain" }} />
                    </Stack>
                  )
                }

                  </Stack>
                  {
                    list && (
                      <Stack spacing={0.5} pt={1} alignItems="start">
                        {list.map((item) => (
                          <Stack direction="row" spacing={1} alignItems="center">
                            <img width={16} height={16} src={check} alt="check" style={{ objectFit: "contain" }} />
                            <Typography>{item}</Typography>
                          </Stack>
                        ))}
                      </Stack>
                    )
                  }
                </Grid>
              </>
                ))}
          </Grid>
        </Container>
      </Container>
      {showModal && (
        <ProductModal
          onClose={() => setShowModal(false)}
          subject="Invesment Solution - Due Diligence Portal"
          buttonColor="secondary"
        />
      )}
    </ThemeProvider>
  );
}

export default DueDiligenceHome;

const StyledBox = styled(Box)`
  width: 60px;
  height: 60px;
  min-width: 60px;
  display: grid;
  border-radius: 50%;
  place-content: center;
  background-color: rgb(194 44 116 / 5%);
`;
